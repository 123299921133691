














































































































































import { Component, Mixins } from 'vue-property-decorator';

import {
  ListingFilter,
  TestimonialFilter,
} from 'client-website-ts-library/filters';
import {
  Listing,
  ListingCategory,
  ListingStatus,
  Office,
  Profile,
  Testimonial,
  WebsiteLevel,
} from 'client-website-ts-library/types';
import { API, Config } from 'client-website-ts-library/services';

import { View } from 'client-website-ts-library/plugins';
import Offices from '@/components/Offices.vue';
import Profiles from '@/components/Profiles.vue';
import ImageBox from '@/components/ImageBox.vue';
import Awards from '@/components/Awards.vue';
import VideoPlayer from '@/components/VideoPlayer.vue';
import { ProfileFilter } from 'client-website-ts-library/filters/ProfileFilter';
import GridNavigation from '@/components/GridNavigation.vue';
import GridNavigationItem from '@/components/GridNavigationItem.vue';
import ListingCardCycler from '@/components/Cycler/ListingCardCycler.vue';
import TestimonialCycler from '@/components/Testimonials/TestimonialCycler.vue';
import AgentCardCycler from '@/components/Cycler/AgentCardCycler.vue';
import LinkButton from '@/components/UI/LinkButton.vue';
import NavigationLink from '@/components/Common/NavigationLink.vue';
import ProfileCards from '@/components/ProfileCards.vue';

@Component({
  components: {
    Offices,
    ImageBox,
    Awards,
    Profiles,
    VideoPlayer,
    GridNavigation,
    GridNavigationItem,
    ListingCardCycler,
    TestimonialCycler,
    AgentCardCycler,
    LinkButton,
    NavigationLink,
    ProfileCards,
  },
})
export default class Home extends Mixins(View) {
  public office: Office | null = null;

  public profiles: Profile[] = [];

  private readonly inRealEstate!: string;

  private testimonials: Testimonial[] | null = null;

  public testimonialCount = 0;

  public listings: Listing[] | null = null;

  public featuredListings: Listing[] | null = null;

  // eslint-disable-next-line
  get month(): number {
    return new Date().getMonth() + 1;
  }

  public testimonialFilter: TestimonialFilter = new TestimonialFilter({
    SearchLevel: WebsiteLevel.Office,
    SearchGuid: Config.Website.Settings!.WebsiteId,
  });

  private listingFilter: ListingFilter = new ListingFilter({
    Categories: [ListingCategory.ResidentialRental],
    Statuses: [ListingStatus.Current, ListingStatus.UnderContract],
    PageSize: 10,
  });

  private FeaturedlistingFilter: ListingFilter = new ListingFilter({
    Categories: [
      ListingCategory.ResidentialSale,
      ListingCategory.ResidentialLand,
      ListingCategory.Commercial,
      ListingCategory.CommercialLand,
      ListingCategory.Business,
    ],
    Statuses: [ListingStatus.Current, ListingStatus.UnderContract],
    IsFeatured: true,
    PageSize: 10,
  });

  mounted() {
    API.Offices.Get(Config.Website.Settings!.WebsiteId).then((office) => {
      this.office = office;
    });

    API.Profiles.Search(
      new ProfileFilter({
        SearchLevel: WebsiteLevel.Office,
        SearchGuid: Config.Website.Settings!.WebsiteId,
      }),
      true,
    ).then((profiles) => {
      // const p2 = profiles;
      // this.profiles = [...p2, ...p2, ...p2];
      profiles.push(...[
        {
          Id: '163dfa24-7cf9-478e-b7fa-04cdd255475f',
          FirstName: 'Patrick',
          LastName: 'Richardson',
          Position: 'Director/Principal',
          DisplayPriority: 0,
          DisplayType: 4,
          OfficePhone: '0497 150 839',
          MobilePhone: '0497 150 839',
          Email: 'prichardson@exploreproperty.com.au',
          Website: 'explorepropertyrockhampton.com.au',
          FacebookUrl:
            'https://www.facebook.com/profile.php?id=100091464996128',
          FacebookHandle: '',
          LinkedInUrl: '',
          LinkedInHandle: '',
          TwitterUrl: '',
          TwitterHandle: '',
          RateMyAgentUrl: 'EPR4700QLD',
          RateMyAgentHandle: '',
          InstagramUrl: 'https://www.instagram.com/explorepropertyrockhampton/',
          InstagramHandle: '',
          AboutAgent:
            "<p>Patrick Richardson, the owner and principal of Explore Property Rockhampton, welcomes you to a world of exceptional real estate opportunities.</p><p>With an unwavering commitment to professionalism, Patrick understands your needs and strives to deliver results that exceed your expectations. As your trusted advisor, he will guide you through the intricate world of real estate, empowering you with knowledge and expertise every step of the way.</p><p>Whether you are buying, selling, or investing, Patrick's comprehensive understanding of the market ensures that you make well-informed decisions to maximize your investments. Utilizing a proactive approach, he leverages his extensive network and innovative marketing strategies to bring unmatched value to your real estate journey.</p><p>Trust Patrick Richardson to provide you with unparalleled service and help you achieve your real estate goals with confidence and peace of mind.</p>",
          WebsiteVideoLink: '',
          EmbeddedWebsiteVideo: null as any,
          Photo: {
            Thumbs: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/PatrickRichardson/Agent/Thumbs/65281564-3657-4f96-b1f7-c653a470ff59.png',
            },
            Preview: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/PatrickRichardson/Agent/Preview/65281564-3657-4f96-b1f7-c653a470ff59.png',
            },
            Large: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/PatrickRichardson/Agent/Large/65281564-3657-4f96-b1f7-c653a470ff59.png',
            },
            Dynamic:
              '/PhotoLibrary/Explore_Property/Rockhampton/PatrickRichardson/Agent/Large/65281564-3657-4f96-b1f7-c653a470ff59.png',
          },
          PROLISTPhoto: {
            Thumbs: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/PatrickRichardson/Agent/Thumbs/1ad1c048-03ae-4426-86cd-0068580ca50d.png',
            },
            Preview: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/PatrickRichardson/Agent/Preview/1ad1c048-03ae-4426-86cd-0068580ca50d.png',
            },
            Large: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/PatrickRichardson/Agent/Large/1ad1c048-03ae-4426-86cd-0068580ca50d.png',
            },
            Dynamic:
              '/PhotoLibrary/Explore_Property/Rockhampton/PatrickRichardson/Agent/Large/1ad1c048-03ae-4426-86cd-0068580ca50d.png',
          },
          CoverPhoto: {
            Thumbs: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/PatrickRichardson/Agent/Thumbs/3201fa3c-c276-499d-93a6-b7b96c18a0cb.png',
            },
            Preview: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/PatrickRichardson/Agent/Preview/3201fa3c-c276-499d-93a6-b7b96c18a0cb.png',
            },
            Large: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/PatrickRichardson/Agent/Large/3201fa3c-c276-499d-93a6-b7b96c18a0cb.png',
            },
            Dynamic:
              '/PhotoLibrary/Explore_Property/Rockhampton/PatrickRichardson/Agent/Large/3201fa3c-c276-499d-93a6-b7b96c18a0cb.png',
          },
          Office: {
            Id: 'fd0fc02c-7b22-445b-bc71-9e5580f3e356',
            OfficeName: 'Rockhampton',
            FranchiseName: 'Explore Property',
            Phone: '',
            Email: 'admin.rockhampton@exploreproperty.com.au',
            Fax: '',
            WebsitePhotoMain: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/Website/ea8047c7-c5ff-45c1-a803-3e44225377c8.jpg',
            },
            WebsitePhotoPreview: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/Website/d5c27de3-7ded-460e-bc1f-4e73edae7f32.jpg',
            },
            CompanyName: 'Explore Property Rockhampton and Yeppoon',
            About: '',
            ABN: '',
            DisplayAddress: true,
            AddressLine1: '2/240 Canning Street, ',
            AddressLine2: 'Allenstown QLD 4700',
            POBoxLine1: null as any,
            POBoxLine2: null as any,
            Coordinate: null as any,
            VideoURL: '',
            ExternalLinks: [
              {
                Url: 'https://www.facebook.com/profile.php?id=100091464996128',
                Type: 1,
              },
              {
                Url: 'https://www.instagram.com/explorepropertyrockhampton/',
                Type: 7,
              },
            ],
            EmailAddresses: ['admin.rockhampton@exploreproperty.com.au'],
            OfficeSlug: 'rockhampton',
            FranchiseSlug: 'explore-property',
            FullSlug: 'explore-property-rockhampton',
          },
          FullName: 'Patrick Richardson',
          Slug: 'patrickrichardson',
        },
        {
          Id: 'c982685e-488e-4780-ae80-7dc058ff42a9',
          FirstName: 'Maddison',
          LastName: 'Gorman',
          Position: 'Director',
          DisplayPriority: 0,
          DisplayType: 4,
          OfficePhone: '',
          MobilePhone: '',
          Email: 'mgorman@exploreproperty.com.au',
          Website: 'explorepropertyrockhampton.com.au',
          FacebookUrl: 'https://www.facebook.com/profile.php?id=100091464996128',
          FacebookHandle: '',
          LinkedInUrl: '',
          LinkedInHandle: '',
          TwitterUrl: '',
          TwitterHandle: '',
          RateMyAgentUrl: 'EPR4700QLD',
          RateMyAgentHandle: '',
          InstagramUrl: 'https://www.instagram.com/explorepropertyrockhampton/',
          InstagramHandle: '',
          AboutAgent: '<p>Maddison is passionate about helping people realise their property dreams. With a background in marketing and management, Maddison is keen to bring a fresh outlook to the Rockhampton and Capricorn Coast regions.</p>',
          WebsiteVideoLink: '',
          EmbeddedWebsiteVideo: null,
          Photo: {
            Thumbs: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/MaddisonGorman/Agent/Thumbs/a1250319-bf2d-4090-8411-3f8ad416aeb1.png',
            },
            Preview: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/MaddisonGorman/Agent/Preview/a1250319-bf2d-4090-8411-3f8ad416aeb1.png',
            },
            Large: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/MaddisonGorman/Agent/Large/a1250319-bf2d-4090-8411-3f8ad416aeb1.png',
            },
            Dynamic: '/PhotoLibrary/Explore_Property/Rockhampton/MaddisonGorman/Agent/Large/a1250319-bf2d-4090-8411-3f8ad416aeb1.png',
          },
          PROLISTPhoto: {
            Thumbs: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/MaddisonGorman/Agent/Thumbs/aecfd3ab-39fb-481c-a3da-3a7b841469d9.png',
            },
            Preview: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/MaddisonGorman/Agent/Preview/aecfd3ab-39fb-481c-a3da-3a7b841469d9.png',
            },
            Large: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/MaddisonGorman/Agent/Large/aecfd3ab-39fb-481c-a3da-3a7b841469d9.png',
            },
            Dynamic: '/PhotoLibrary/Explore_Property/Rockhampton/MaddisonGorman/Agent/Large/aecfd3ab-39fb-481c-a3da-3a7b841469d9.png',
          },
          CoverPhoto: null,
          Office: {
            Id: 'fd0fc02c-7b22-445b-bc71-9e5580f3e356',
            OfficeName: 'Rockhampton',
            FranchiseName: 'Explore Property',
            Phone: '',
            Email: 'admin.rockhampton@exploreproperty.com.au',
            Fax: '',
            WebsitePhotoMain: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/Website/ea8047c7-c5ff-45c1-a803-3e44225377c8.jpg',
            },
            WebsitePhotoPreview: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/Website/d5c27de3-7ded-460e-bc1f-4e73edae7f32.jpg',
            },
            CompanyName: 'Explore Property Rockhampton and Yeppoon',
            About: '',
            ABN: '',
            DisplayAddress: true,
            AddressLine1: '2/240 Canning Street, ',
            AddressLine2: 'Allenstown QLD 4700',
            POBoxLine1: null,
            POBoxLine2: null,
            Coordinate: null,
            VideoURL: '',
            ExternalLinks: [
              {
                Url: 'https://www.facebook.com/profile.php?id=100091464996128',
                Type: 1,
              },
              {
                Url: 'https://www.instagram.com/explorepropertyrockhampton/',
                Type: 7,
              },
            ],
            EmailAddresses: ['admin.rockhampton@exploreproperty.com.au'],
            OfficeSlug: 'rockhampton',
            FranchiseSlug: 'explore-property',
            FullSlug: 'explore-property-rockhampton',
          },
          FullName: 'Maddison Gorman',
          Slug: 'maddisongorman',
        } as any,
        {
          Id: '64fc1f77-eea4-46b0-8cbe-e71ffdbe7125',
          FirstName: 'Courtney',
          LastName: 'Klerkx',
          Position: 'Administration',
          DisplayPriority: 0,
          DisplayType: 6,
          OfficePhone: '0497 150 839',
          MobilePhone: '',
          Email: 'admin.rockhampton@exploreproperty.com.au',
          Website: '',
          FacebookUrl: '',
          FacebookHandle: '',
          LinkedInUrl: '',
          LinkedInHandle: '',
          TwitterUrl: '',
          TwitterHandle: '',
          RateMyAgentUrl: 'EPR4700QLD',
          RateMyAgentHandle: '',
          InstagramUrl: '',
          InstagramHandle: '',
          AboutAgent: '',
          WebsiteVideoLink: '',
          EmbeddedWebsiteVideo: null as any,
          Photo: {
            Thumbs: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/Courtney.Klerkx/Agent/Thumbs/4387744f-1315-4137-86c7-cacc5ad9209c.png',
            },
            Preview: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/Courtney.Klerkx/Agent/Preview/4387744f-1315-4137-86c7-cacc5ad9209c.png',
            },
            Large: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/Courtney.Klerkx/Agent/Large/4387744f-1315-4137-86c7-cacc5ad9209c.png',
            },
            Dynamic:
              '/PhotoLibrary/Explore_Property/Rockhampton/Courtney.Klerkx/Agent/Large/4387744f-1315-4137-86c7-cacc5ad9209c.png',
          },
          PROLISTPhoto: {
            Thumbs: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/Courtney.Klerkx/Agent/Thumbs/da95a256-81d8-4530-95f7-bd6bf2a6925e.png',
            },
            Preview: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/Courtney.Klerkx/Agent/Preview/da95a256-81d8-4530-95f7-bd6bf2a6925e.png',
            },
            Large: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/Courtney.Klerkx/Agent/Large/da95a256-81d8-4530-95f7-bd6bf2a6925e.png',
            },
            Dynamic:
              '/PhotoLibrary/Explore_Property/Rockhampton/Courtney.Klerkx/Agent/Large/da95a256-81d8-4530-95f7-bd6bf2a6925e.png',
          },
          CoverPhoto: null as any,
          Office: {
            Id: 'fd0fc02c-7b22-445b-bc71-9e5580f3e356',
            OfficeName: 'Rockhampton',
            FranchiseName: 'Explore Property',
            Phone: '',
            Email: 'admin.rockhampton@exploreproperty.com.au',
            Fax: '',
            WebsitePhotoMain: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/Website/ea8047c7-c5ff-45c1-a803-3e44225377c8.jpg',
            },
            WebsitePhotoPreview: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/Website/d5c27de3-7ded-460e-bc1f-4e73edae7f32.jpg',
            },
            CompanyName: 'Explore Property Rockhampton and Yeppoon',
            About: '',
            ABN: '',
            DisplayAddress: true,
            AddressLine1: '2/240 Canning Street, ',
            AddressLine2: 'Allenstown QLD 4700',
            POBoxLine1: null as any,
            POBoxLine2: null as any,
            Coordinate: null as any,
            VideoURL: '',
            ExternalLinks: [
              {
                Url: 'https://www.facebook.com/profile.php?id=100091464996128',
                Type: 1,
              },
              {
                Url: 'https://www.instagram.com/explorepropertyrockhampton/',
                Type: 7,
              },
            ],
            EmailAddresses: ['admin.rockhampton@exploreproperty.com.au'],
            OfficeSlug: 'rockhampton',
            FranchiseSlug: 'explore-property',
            FullSlug: 'explore-property-rockhampton',
          },
          FullName: 'Courtney Klerkx',
          Slug: 'courtneyklerkx',
        },
        {
          Id: '50ed7973-c673-49c0-b761-f33238f7a10a',
          FirstName: 'Kailah',
          LastName: 'Morriss',
          Position: 'Business Development Manager',
          DisplayPriority: 0,
          DisplayType: 2,
          OfficePhone: '0491 236 788',
          MobilePhone: '0491 236 788',
          Email: 'kmorriss@exploreproperty.com.au',
          Website: '',
          FacebookUrl: '',
          FacebookHandle: '',
          LinkedInUrl: '',
          LinkedInHandle: '',
          TwitterUrl: '',
          TwitterHandle: '',
          RateMyAgentUrl: 'EPR4700QLD',
          RateMyAgentHandle: '',
          InstagramUrl: '',
          InstagramHandle: '',
          AboutAgent:
            '<p>With over 10 years experience in the real estate industry, Kailah knows and understands what it takes to manage your biggest asset. Kailah&nbsp;is a BDM with real experience who knows the ins and outs of all things property management. Contact Kailah today if you want to "keep you investment in safe hands".</p>',
          WebsiteVideoLink: '',
          EmbeddedWebsiteVideo: null as any,
          Photo: {
            Thumbs: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/KailahMorriss/Agent/Thumbs/71a91607-246b-4814-9542-c3d8b6029df2.png',
            },
            Preview: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/KailahMorriss/Agent/Preview/71a91607-246b-4814-9542-c3d8b6029df2.png',
            },
            Large: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/KailahMorriss/Agent/Large/71a91607-246b-4814-9542-c3d8b6029df2.png',
            },
            Dynamic:
              '/PhotoLibrary/Explore_Property/Rockhampton/KailahMorriss/Agent/Large/71a91607-246b-4814-9542-c3d8b6029df2.png',
          },
          PROLISTPhoto: {
            Thumbs: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/KailahMorriss/Agent/Thumbs/bd9c2865-3ee7-4239-ae0e-c47442aba23f.png',
            },
            Preview: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/KailahMorriss/Agent/Preview/bd9c2865-3ee7-4239-ae0e-c47442aba23f.png',
            },
            Large: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/KailahMorriss/Agent/Large/bd9c2865-3ee7-4239-ae0e-c47442aba23f.png',
            },
            Dynamic:
              '/PhotoLibrary/Explore_Property/Rockhampton/KailahMorriss/Agent/Large/bd9c2865-3ee7-4239-ae0e-c47442aba23f.png',
          },
          CoverPhoto: null as any,
          Office: {
            Id: 'fd0fc02c-7b22-445b-bc71-9e5580f3e356',
            OfficeName: 'Rockhampton',
            FranchiseName: 'Explore Property',
            Phone: '',
            Email: 'admin.rockhampton@exploreproperty.com.au',
            Fax: '',
            WebsitePhotoMain: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/Website/ea8047c7-c5ff-45c1-a803-3e44225377c8.jpg',
            },
            WebsitePhotoPreview: {
              Url: 'https://images.prolist.net.au/PhotoLibrary/Explore_Property/Rockhampton/Website/d5c27de3-7ded-460e-bc1f-4e73edae7f32.jpg',
            },
            CompanyName: 'Explore Property Rockhampton and Yeppoon',
            About: '',
            ABN: '',
            DisplayAddress: true,
            AddressLine1: '2/240 Canning Street, ',
            AddressLine2: 'Allenstown QLD 4700',
            POBoxLine1: null as any,
            POBoxLine2: null as any,
            Coordinate: null as any,
            VideoURL: '',
            ExternalLinks: [
              {
                Url: 'https://www.facebook.com/profile.php?id=100091464996128',
                Type: 1,
              },
              {
                Url: 'https://www.instagram.com/explorepropertyrockhampton/',
                Type: 7,
              },
            ],
            EmailAddresses: ['admin.rockhampton@exploreproperty.com.au'],
            OfficeSlug: 'rockhampton',
            FranchiseSlug: 'explore-property',
            FullSlug: 'explore-property-rockhampton',
          },
          FullName: 'Kailah Morriss',
          Slug: 'kailahmorriss',
        } as any,
      ]);

      this.profiles = profiles;
    });

    // this.loadTestimonials();
    this.loadListings();
    this.loadFeaturedListings();
  }

  loadTestimonials(): void {
    API.Testimonials.Search(this.testimonialFilter).then((testimonials) => {
      this.testimonials = testimonials;
    });
  }

  loadListings(): void {
    API.Listings.Search(this.listingFilter).then((listings) => {
      this.listings = listings.Items;
    });
  }

  loadFeaturedListings(): void {
    API.Listings.Search(this.FeaturedlistingFilter).then((listings) => {
      this.featuredListings = listings.Items;
    });
  }

  updateTestimonialCount(count: number) {
    this.testimonialCount = count;
  }
  // End of export
}
